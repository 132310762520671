import { useEffect, useState } from "react";
import sanityClient from "../client";
import { motion } from "framer-motion";


import "./Home.scss";
import EntryItem from "../components/home/EntryItem";

const Home = () => {
  const [entries, setEntries] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "entry"] | order(releaseDate desc)`)
      .then((data) => setEntries(data))
      .catch(console.error);
  }, []);

  const entryItems = document.querySelectorAll(".home__entry-item");

  const handleClick = (e) => {
    entryItems.forEach((entry) => {
      entry.classList.remove("active");
    });
    e.target.classList.add("active");
  };



  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.4 } }}
      className="home__outer-wrapper"
    >
      <div className="home__entries-wrapper">
        {entries &&
          entries.map((entry, index) => {
            return (
              <EntryItem {...{
                entry,
                handleClick
              }} key={index} />
            );
          })}
      </div>
    </motion.main>
  );
};

export default Home;
