import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import sanityClient from "./client";

import Home from "./views/Home";
import Entry from "./views/Entry";
import Information from "./views/Information";

import InformationLeft from "./components/information/InformationLeft";
import SplashPage from "./components/global/SplashPage";
import Header from "./components/global/Header";
import MarqueeComp from "./components/global/Marquee";

import "./styles/global-styles.scss";

// import ReactGA from "react-ga";

function App() {
  const location = useLocation();
  const [contentOpen, setContentOpen] = useState(false);
  const [informationOpen, setInformationOpen] = useState(false);
  const [informationData, setInformationData] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   ReactGA.initialize("G-TM95KQ8TY9");
  //   ReactGA.set({ page: location.pathname });
  //   ReactGA.pageview(location.pathname);
  // }, [location]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "information"][0]`)
      .then((data) => {
        setInformationData(data);
      })
      .catch();
  }, []);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  const handleGoHome = () => {
    const list = document.querySelectorAll(".home__entry-item");
    for (let i = 0; i < list.length; i++) {
      list[i].classList.remove("active");
    }
    setContentOpen(false);
    setInformationOpen(false);
  };

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth < 750) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    resize();
    window.addEventListener("resize", resize, false);
    return window.removeEventListener("resize", resize, false);
  }, []);

  return (
    <AnimatePresence>
      <Header
        {...{
          informationData,
          contentOpen,
          handleGoHome,
          informationOpen,
        }}
      />
      <SplashPage />
      {!informationOpen ? (
        <Home />
      ) : (
        <InformationLeft
          {...{
            informationData,
            isMobile,
          }}
        />
      )}

      <div className={`content__wrapper ${contentOpen ? "active" : ""}`}>
        <Routes location={location} key={location.pathname}>
          <Route
            element={
              <Entry
                key={"entry"}
                {...{
                  setContentOpen,
                }}
              />
            }
            path="/entry/:slug"
          />
          <Route
            element={
              <Information
                key={"information"}
                {...{
                  setInformationOpen,
                  setContentOpen,
                  informationData,
                  isMobile,
                }}
              />
            }
            path="/information"
          />
        </Routes>{" "}
      </div>
      <MarqueeComp />
    </AnimatePresence>
  );
}

export default App;
