import { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import sanityClient from "../../client";

import "./Marquee.scss";

const MarqueeComp = () => {

  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "marquee"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);
  
  if(pageData){
    if(pageData.showHide === true){
      return (
        <a 
        href={pageData.marqueeLink}
        target="_blank"
        rel="noreferrer" 
        className='marquee__wrapper'
        >
          <Marquee autoFill={true}>
            {pageData.marqueeText}&nbsp;&nbsp;&#8226;&nbsp;&nbsp;
          </Marquee>
        </a>
      )
    } else {
      return;
    }
  }
}

export default MarqueeComp