import { Link } from "react-router-dom";
import "./Header.scss";
import { useEffect, useState } from "react";

const Header = (props) => {
  const {
    informationData,
    contentOpen,
    handleGoHome,
    informationOpen,
  } = props;
// 
  // const [navItems, setNavItems] = useState([])
  // const [entryItems, setEntryItems] = useState([])

  // useEffect(()=>{
  //   setNavItems(document.querySelectorAll(".header__nav-item"))
  //   setEntryItems(document.querySelectorAll(".home__entry-item"))
  // },[])
  // const navItems = document.querySelectorAll(".header__nav-item");
  // const entryItems = document.querySelectorAll(".home__entry-item");

  useEffect(()=>{

    const handleFilter = (e) => {
      const value = e.target.dataset.value;
      navItems.forEach((item) => {
        item.classList.remove("disabled");
      });
      entryItems.forEach((entry) => {
        entry.classList.remove("filtered-out");
        if (value !== "all") {
          if (!entry.classList.contains(value)) {
            entry.classList.add("filtered-out");
          }
        }
      });
      e.target.classList.add("disabled");
    };

      const navItems = document.querySelectorAll(".header__nav-item");
      const entryItems = document.querySelectorAll(".home__entry-item");

      // console.log(navItems);
      navItems.forEach((item)=>{
        item.addEventListener('click', handleFilter, false);
      })

  })

  // const handleFilter = (e) => {
  //   const value = e.target.dataset.value;
  //   navItems.forEach((item) => {
  //     item.classList.remove("disabled");
  //   });
  //   entryItems.forEach((entry) => {
  //     entry.classList.remove("filtered-out");
  //     if (value !== "all") {
  //       if (!entry.classList.contains(value)) {
  //         entry.classList.add("filtered-out");
  //       }
  //     }
  //   });
  //   e.target.classList.add("disabled");
  // };

  if (informationData) {
    return (
      <div className="header__wrapper">
        <Link to={"/"} className="header__logo-wrapper" onClick={handleGoHome}>
          <h1>oneohtrix</h1>
          <h1>point</h1>
          <h1>never</h1>
        </Link>
        <div className="header__nav-wrapper">
          <div className="header__nav-inner-left">
            <div className="header__nav-inner-left-inner">
              <Link
                to={"/"}
                className={`header__nav-item-back ${
                  contentOpen ? "active" : ""
                }`}
                onClick={handleGoHome}
              >
                &larr; Back
              </Link>
              <div
                className={`header__nav-items-wrapper ${
                  contentOpen ? "disabled" : ""
                }`}
              >
                <span style={{marginRight: "10px"}}>Filter:</span>
                <button
                  className="header__nav-item disabled"
                  // onClick={handleFilter}
                  data-value="all"
                >
                  All
                </button>
                <button
                  className="header__nav-item"
                  // onClick={handleFilter}
                  data-value="live"
                >
                  Live
                </button>
                <button
                  className="header__nav-item"
                  // onClick={handleFilter}
                  data-value="releases"
                >
                  Releases
                </button>
                <button
                  className="header__nav-item"
                  // onClick={handleFilter}
                  data-value="video"
                >
                  Videos
                </button>
                <button
                  className="header__nav-item"
                  // onClick={handleFilter}
                  data-value="radio"
                >
                  Radio
                </button>
              </div>
            </div>
          </div>
          <div className="header__nav-inner-right">
            <div className="header__nav-inner-right-inner">
            <a
                href={"https://pointnever.com/"}
                className="header__nav-item"
                target="_blank"
                rel="noreferrer"
              >
                Archive
              </a>
              
              <Link
                to={"/information"}
                className={`header__nav-item ${
                  informationOpen ? "disabled" : ""
                }`}
              >
                Information
              </Link>
              <a
                href={informationData.merchLink}
                className="header__nav-item"
                target="_blank"
                rel="noreferrer"
              >
                Merch
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return;
  }
};

export default Header;
