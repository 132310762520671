import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import formatDate from "../../hooks/formatDate";

const EntryItem = (props) => {
  const { entry, handleClick } = props;
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    let filterNames = [];
    if (entry.categories.live) {
      filterNames.push("live");
    }
    if (entry.categories.video) {
      filterNames.push("video");
    }
    if (entry.categories.releases) {
      filterNames.push("releases");
    } 
    if (entry.categories.radio) {
      filterNames.push("radio");
    }
    setFilters(filterNames);
  }, [entry.categories.live, entry.categories.video, entry.categories.releases, entry.categories.radio]);
  return (
    <Link
      to={`/entry/${entry.slug.current}`}
      className={`home__entry-item ${filters.join(" ")}`}
      onClick={handleClick}
    >
      <h1>{entry.title} <span className="alt-font">{entry.location}, {formatDate(entry.releaseDate)}</span></h1>
    </Link>
  );
};

export default EntryItem;
