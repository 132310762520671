import React from "react";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../client.js";

import useProgressiveImg from "../../hooks/useProgressiveImage";

const Image = (props) => {
  const { imageAsset, styles, alt } = props;

  const builder = imageUrlBuilder(client);

  function urlFor(source) {
    return builder.image(source);
  }

  const smallUrl = `${urlFor(imageAsset)}?q=10`;
  const largeUrl = `${urlFor(imageAsset)}`;

  const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  return (
    <img
      src={src}
      className={styles}
      alt={alt}
      style={{
        filter: blur ? "blur(20px)" : "none",
        transition: blur ? "none" : "filter 0.3s ease-out",
      }}
    />
  );
};

export default Image;