import React from "react";

const Vimeo = ({ id }) => {
  return (
    <>
      <div style={{padding:'60% 0 0 0', position:'relative'}}>
        <iframe
          title="VimeoEmbed"
          src={`https://player.vimeo.com/video/${id}`}
          style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  );
};

export default Vimeo;
