import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { PortableText } from "@portabletext/react";
import { LinkWithBrackets } from "../hooks/usePortableTextComponents";
import sanityClient from "../client";

import formatDate from "../hooks/formatDate";
import Loading from "../components/global/Loading";
import Image from "../components/global/Image";
import Vimeo from "../components/entry/Vimeo";
import YouTube from "../components/entry/YouTube";

const Entry = (props) => {
  const { setContentOpen } = props;
  const { slug } = useParams();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[slug.current == $slug]`, { slug })
      .then((data) => {
        setPageData(data[0]);
      })
      .catch();
  }, [slug]);

  useEffect(() => {
    setContentOpen(true);
  }, [setContentOpen]);

  if (pageData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="home__outer-wrapper"
      >
        <div className="content__title alt-font">
          <span>
            {pageData.title} — {pageData.location}
          </span>
          <span>{formatDate(pageData.releaseDate)}</span>
        </div>
        {pageData.pageBuilder &&
          pageData.pageBuilder.map((media, index) => {
            if (media._type === "imageMedia") {
              return (
                <div className="content__media-wrapper" key={index}>
                  <Image
                    imageAsset={media.imageMedia}
                    styles={"content__media"}
                    alt={media.title}
                  />
                </div>
              );
            } else if (media._type === "vimeoMedia") {
              return (
                <div className="content__media-wrapper" key={index}>
                  <Vimeo id={media.vimeoMedia} />
                </div>
              );
            } else if (media._type === "youtubeMedia") {
              return (
                <div className="content__media-wrapper" key={index}>
                  <YouTube id={media.youtubeMedia} />
                </div>
              );
            } else {
              return('');
            }
          })}
        {pageData.description !== undefined ? (
          <div className="content__text-wrapper">
            <PortableText
              value={pageData.description}
              components={LinkWithBrackets}
            />
          </div>
        ) : (
          ""
        )}

        {pageData.externalLinks !== undefined ? (
          <div className="content__text-wrapper alt-font external">
            {pageData.externalLinks &&
              pageData.externalLinks.map((link, index) => {
                return (
                  <a
                    href={link.entryLink}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    {link.entryName}
                  </a>
                );
              })}
          </div>
        ) : (
          ""
        )}
      </motion.main>
    );
  } else {
    <Loading />;
  }
};

export default Entry;
