import React from 'react'
import { motion } from "framer-motion";
const InformationLeft = (props) => {
    const {
        informationData,
        isMobile
    } = props;
    if(informationData && !isMobile){
        return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.4 } }}
      className="information__left-wrapper"
    >
        <div className='information__section-wrapper'>
            <h1>SOCIALS</h1>
            {informationData.socials && 
            informationData.socials.map((platform, index) => {
                return(
                <a href={platform.entryLink} 
                className='information__social-link alt-font'
                target='_blank'
                rel='noreferrer'
                key={index}
                >{platform.entryName}</a> 
                )
            })}
        </div>
        <div className='information__section-hover-wrapper'>
           <a href={informationData.mailingList} 
           target='_blank'
           rel='noreferrer'
           ><h1>MAILING LIST</h1></a> 
        </div>
        <div className='information__section-hover-wrapper'>
           <a href={"mailto:"+informationData.management}><h1>CONTACT<br />MANAGEMENT</h1></a> 
        </div>

        <div className='information__section-hover-wrapper'>
           <a href={"mailto:"+informationData.bookings}><h1>CONTACT<br />BOOKINGS</h1></a> 
        </div>

    </motion.main>
          )
    } else {
        return;
    }
}

export default InformationLeft