import "./YouTube.scss";

const YouTube = ({ id }) => {
  return (
      <div class="embed-container">
        <iframe
          title="YouTubeEmbed"
          src={`https://www.youtube.com/embed/${id}`}
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
  );
};

export default YouTube;
