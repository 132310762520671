import { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";
import { LinkWithBrackets } from "../../hooks/usePortableTextComponents";
import sanityClient from "../../client";

import YouTube from "../entry/YouTube";
import Vimeo from "../entry/Vimeo";
import Image from "./Image";

import "./SplashPage.scss";

const SplashPage = () => {
  const [splashOpen, setSplashOpen] = useState(false);
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "splashPage"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  useEffect(()=>{
    if(pageData && pageData.showHide){
        setSplashOpen(true);
    } else {
        setSplashOpen(false)
    }
  }, [pageData])

  if (pageData) {
    return (
      <div className={`splash__outer-wrapper ${splashOpen ? "active" : ""}`}>
        <div className="splash__title">{pageData.splashPageTitle}</div>
        <div className="splash__content">
          <div className="splash__content-half">
            {pageData.splashPageMediaType === "image" ? (
              <Image
                imageAsset={pageData.imageMedia}
                styles={"splash__media"}
                alt={pageData.splashPageTitle}
              />
            ) : (
              ""
            )}

            {pageData.splashPageMediaType === "youtube" ? (
              <YouTube
                id={pageData.youtubeMedia}
              />
            ) : (
              ""
            )}

            {pageData.splashPageMediaType === "vimeo" ? (
              <Vimeo
                id={pageData.vimeoMedia}
              />
            ) : (
              ""
            )}
          </div>
          {pageData.splashPageText !== undefined ? (
            <div className="splash__content-half">
                          <PortableText
            value={pageData.splashPageText}
            components={LinkWithBrackets}
          />
            </div>
          ) : (
            ""
          )}
        </div>
        <button className="splash__enter" onClick={() => setSplashOpen(false)}>
          ENTER SITE
        </button>
      </div>
    );
  } else {
    return;
  }
};

export default SplashPage;
