import { useEffect } from "react";
import { PortableText } from "@portabletext/react";
import { LinkWithBrackets } from "../hooks/usePortableTextComponents";
import { motion } from "framer-motion";

import Loading from "../components/global/Loading";
import Image from "../components/global/Image";

import "./Information.scss";

const Information = (props) => {
  const { 
    informationData, 
    setInformationOpen, 
    setContentOpen,
    isMobile
} = props;

  useEffect(() => {
    setInformationOpen(true);
    setContentOpen(true);
  }, [setInformationOpen, setContentOpen]);

  if (informationData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="home__outer-wrapper"
      >
        <div className="content__title alt-font">
          <span>ONEOHTRIX POINT NEVER</span>
          <span>07.25.1982</span>
        </div>
        <div className="content__media-wrapper">
          <Image
            imageAsset={informationData.danImage}
            styles={"content__media"}
            alt={"ONEOHTRIX POINT NEVER"}
          />
        </div>
        <div className="content__text-wrapper information__text">
          <PortableText
            value={informationData.aboutText}
            components={LinkWithBrackets}
          />
        </div>
        {isMobile ? (
          <>
            <div className="information__section-wrapper">
              <h1>SOCIALS</h1>
              {informationData.socials &&
                informationData.socials.map((platform, index) => {
                  return (
                    <a
                      href={platform.entryLink}
                      className="information__social-link alt-font"
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                    >
                      {platform.entryName}
                    </a>
                  );
                })}
            </div>
            <div className="information__section-hover-wrapper">
              <a
                href={informationData.mailingList}
                target="_blank"
                rel="noreferrer"
              >
                <h1>MAILING LIST</h1>
              </a>
            </div>
            <div className="information__section-hover-wrapper">
              <a href={"mailto:" + informationData.management}>
                <h1>
                  CONTACT
                  <br />
                  MANAGEMENT
                </h1>
              </a>
            </div>

            <div className="information__section-hover-wrapper">
              <a href={"mailto:" + informationData.bookings}>
                <h1>
                  CONTACT
                  <br />
                  BOOKINGS
                </h1>
              </a>
            </div>

          </>
        ) : (
          ""
        )}
      </motion.main>
    );
  } else {
    <Loading />;
  }
};

export default Information;
